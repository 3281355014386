<template>
  <div class="library-slider">
    <div class="content">
      <div class="d-flex mb-8">
        <div
          class="library-slider__title mr-8 mb-0 d-flex"
          v-if="title"
          v-html="title"
        ></div>
        <div class="library-slider__title mr-8 mb-0" v-else>
          Дополнительные материалы по теме:
          <div
            :style="`color: ${color}; cursor: pointer`"
            class="library-slider__title_highlight"
            v-if="nosology && nosology.slug"
            @click="
              toNosol({
                name: 'NosologyDetail',
                params: { slug: nosology.slug },
              })
            "
            v-html="nosology.title"
          ></div>
          <span
            :style="`color: ${color}`"
            class="library-slider__title_highlight"
            v-else-if="nosology"
            v-html="nosology.title"
          >
          </span>
        </div>
        <div
          class="library-slider__arrows mt-0 ml-auto d-none d-md-flex"
          v-if="showArrows"
        >
          <div
            class="library-slider__arrow mr-3"
            :style="` --color-hover: ${color}`"
            @click="$refs.librarySlider.prev()"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M5.25 12.0001L20.25 12.0001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div
            class="library-slider__arrow ml-3"
            :style="` --color-hover: ${color}`"
            @click="$refs.librarySlider.next()"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M18.75 12.0001L3.75 12.0001"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>

      <VueSlickCarousel
        v-bind="settings"
        ref="librarySlider"
        @init="onInitCarousel"
        @reInit="onInitCarousel"
      >
        <div class="material-wrap" v-for="(i, ind) in items" :key="ind">
          <Material
            :video="video"
            :item="i"
            :page="page || i.page"
            :hideFavorites="true"
            @clickMaterials="clickMaterials"
          />
        </div>
      </VueSlickCarousel>
      <div class="library-slider__arrows d-md-none" v-if="showArrows">
        <div
          class="library-slider__arrow mr-3"
          :style="` --color-hover: ${color}`"
          @click="$refs.librarySlider.prev()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M5.25 12.0001L20.25 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div
          class="library-slider__arrow ml-3"
          :style="` --color-hover: ${color}`"
          @click="$refs.librarySlider.next()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12.0001L3.75 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Material from "./Material.vue";
export default {
  name: "LibrarySlider",
  props: {
    items: Array,
    nosology: [Object, String],
    video: {
      type: Boolean,
      default: false,
    },
    page: String,
    color: { type: String, default: "#830051" },
    title: String,
  },
  components: {
    VueSlickCarousel,
    Material,
  },
  data: () => ({
    showArrows: true,
    settings: {
      infinite: false,
      slidesToShow: 3,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  }),
  computed: {},
  methods: {
    onInitCarousel() {
      this.showArrows =
        this.$refs.librarySlider?.settings.slidesToShow < this.items.length;
    },
    clickMaterials(name, to) {
      this.$emit("clickMaterials", name);
    },
    toNosol(to) {
      this.$emit("toNosol", this.nosology.title, to);
    },
  },
};
</script>

<style lang="scss" scoped>
.library-slider {
  margin-bottom: -124px;
  padding-bottom: 124px;
  margin-top: 64px;
  padding-top: 64px;
  background-color: #f8f8f8;
  @media screen and (max-width: 1220px) {
    padding-top: 32px;
    margin-top: 32px;
    padding-bottom: 120px;
    margin-bottom: -120px;
  }

  &__title {
    margin-bottom: 32px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }

    &_highlight {
      text-decoration: underline;
      color: #830051;
      transition: 0.3s;
    }
  }

  .material {
    height: 100%;
  }

  .material-wrap {
    height: 100%;
  }

  &__arrows {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__arrow {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #d8dada;
    cursor: pointer;
    color: #8b8e8e;
    transition: 0.3s;

    &:hover {
      color: var(--color-hover);
      background-color: #ebefee;
    }
  }
}
</style>

<style lang="scss">
.library-slider {
  .slick-slider {
    margin: 0 -16px;
  }

  .slick-list,
  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 16px;
    height: auto;
  }

  .slick-slide > div {
    height: 100%;
  }
}
</style>