<template>
  <div>
    <div
      class="event-detail seemore"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="require('@/assets/img/shine/banner_mob.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/shine/banner_tab.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/shine/banner.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="require('@/assets/img/shine/banner.jpg')"
            />
          </picture>
          <div class="first-banner__text">
            <div class="first-banner__title">
              Без боли Без дискомфорта<br />
              Без потери данных
            </div>
            <div class="first-banner__description">
              Современные решения для качественного управления уровнем глюкозы —
              забота о вашем здоровье каждый день
            </div>
            <div
              class="button first-banner__button button_white mb-4"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
              style="color: #3c4242"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_white"
              @click="scrollTo('programm')"
              style="color: #3c4242"
            >
              Программы мероприятий
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="event-detail__about-description mt-14">
          Добро пожаловать в мир мониторинга 3-го поколения SYAI X1. Этот прибор
          для непрерывного мониторинга глюкозы (НМГ) открывает новые горизонты в
          управлении диабетом, сочетая передовые технологии и удобство. SYAI X1
          - это настоящий прорыв: его компактные размеры сравнимый с 5-рублевой
          монетой, не мешают вашей активности, а встроенный искусственный
          интеллект обеспечивает точный и надежный контроль уровня глюкозы в
          реальном времени<sup>1</sup>.
          <br />
          <br />
          <b>Комфорт. Безопасность. Искусственный интеллект.</b>
          <br />
          Узнайте, как Syai X1 меняет жизнь пациентов и врачей, делая контроль
          глюкозы проще, удобнее и безопаснее, чем когда-либо.
          <br />
          <br />
          <b>Зарегистрируйтесь и откройте будущее вместе с нами!</b>
        </div>

        <swiper class="my-16" v-bind="swiperSettings">
          <swiper-slide>
            <img
              :srcset="`${require('@/assets/img/shine/sections/1.png')} 2x`"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              :srcset="`${require('@/assets/img/shine/sections/2.png')} 2x`"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              :srcset="`${require('@/assets/img/shine/sections/3.png')} 2x`"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              :srcset="`${require('@/assets/img/shine/sections/4.png')} 2x`"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              :srcset="`${require('@/assets/img/shine/sections/5.png')} 2x`"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              :srcset="`${require('@/assets/img/shine/sections/6.png')} 2x`"
            />
          </swiper-slide>
        </swiper>

        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-title">Мониторинг <br />глюкозы</div>
            <div class="event-detail__date-item" v-if="EventDetail.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                07 декабря, 10:00 - 12:50 (мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.types">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                {{ EventDetail.types[0].name }}
              </div>
            </div>
            <div class="event-detail__date-buttons">
              <div
                class="button event-detail__date-button button_lilac"
                @click="scrollTo('programm')"
              >
                Программа
              </div>
              <div
                class="button event-detail__date-button button_lilac"
                @click="toRegister(true)"
                v-if="!Object.keys(user).length"
              >
                Зарегистрироваться
              </div>
            </div>
            <div
              v-if="Object.keys(timerResult).length && Object.keys(user).length"
              class="event-detail__timer-row"
            >
              <div class="event-detail__timer-item" v-if="timerResult.months">
                <div class="timer__value">{{ timerResult.months }}</div>
                <div class="timer__text">м</div>
              </div>
              <div class="event-detail__timer-item" v-if="timerResult.days > 0">
                <div class="timer__value">{{ timerResult.days }}</div>
                <div class="timer__text">
                  {{ getNoun(timerResult.days, "д.", "д.", "д.") }}
                </div>
              </div>
              <div
                class="event-detail__timer-item"
                v-if="timerResult.hours > 0"
              >
                <div class="timer__value">{{ timerResult.hours }}</div>
                <div class="timer__text">
                  {{ getNoun(timerResult.hours, "ч.", "ч.", "ч.") }}
                </div>
              </div>
              <div
                class="event-detail__timer-item"
                v-if="timerResult.minutes > 0"
              >
                <div class="timer__value">{{ timerResult.minutes }}</div>
                <div class="timer__text">
                  {{ getNoun(timerResult.minutes, "м.", "м.", "м.") }}
                </div>
              </div>
              <div
                class="event-detail__timer-item"
                v-if="timerResult.seconds > 0"
              >
                <div class="timer__value">{{ timerResult.seconds }}</div>
                <div class="timer__text">
                  {{ getNoun(timerResult.seconds, "с.", "с.", "с.") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="second-banner">
          <div class="second-banner__text">
            <div class="second-banner__title">Мониторинг от А до Я</div>
            <div class="second-banner__description">
              Все о контроле диабета: узнайте больше для эффективного управления
              уровнем глюкозы
            </div>
            <div class="second-banner__button-container">
              <a
                class="button second-banner__button button_white"
                href="https://az-most.ru/monitoring"
                target="_blank"
                style="color: #3c4242"
              >
                Подробнее
              </a>
            </div>
          </div>
          <picture>
            <source
              :srcset="require('@/assets/img/shine/second-banner_mob.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/shine/second-banner_tab.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/shine/second-banner.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="second-banner__image"
              :src="require('@/assets/img/shine/second-banner.jpg')"
            />
          </picture>
        </div>
        <DefaultSlider
          :color="'#6D6DB1'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
          class="mt-10"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div>
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>
        <div class="third-banner">
          <div class="third-banner__text">
            <div class="third-banner__title">Ли Яньбин</div>
            <div class="third-banner__description">
              <div class="mb-4">
                Профессор, главный врач, научный руководитель, директор
                отделения эндокринологии в Первой дочерней больнице университета
                имени Сунь Ятсен (Sun Yat-sen). Главный эксперт Национального
                проекта по основным хроническим заболеваниям, ведущий
                медицинский эксперт в провинции Гуандун (Guangdong Province).
              </div>
            </div>
          </div>
          <picture>
            <source
              :srcset="require('@/assets/img/shine/third-banner_mob.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/shine/third-banner_tab.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/shine/third-banner.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="third-banner__image"
              :src="require('@/assets/img/shine/third-banner.jpg')"
            />
          </picture>
        </div>
        <div class="event-detail__main-info mt-16" id="programm">
          <div class="event-detail__about-title">
            Программа на 07 декабря 2024 г.
          </div>
          <div class="event-detail__tip">(указано московское время)</div>
          <div class="mt-8">
            <div v-html="programmHTML(program.items)"></div>
          </div>
        </div>
      </div>
      <LibrarySlider
        v-if="sliderItems && sliderItems.length && !sliderItems.includes(null)"
        :items="sliderItems"
        :color="'#2186AF'"
        :title="'Это может быть полезным'"
        @clickMaterials="clickMaterials"
      />
      <div class="dropdowns">
        <div class="content">
          <InfoDrop
            :item="{
              title: 'Список сокращений',
              content: shortsList,
            }"
          />
          <InfoDrop
            :item="{
              title: 'Список литературы',
              content: litList,
            }"
          />
        </div>
      </div>
    </div>
    <Facecast
      :slug="'bolshe'"
      @closeFrame="openFrame = false"
      :open="openFrame"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import LibrarySlider from "@/components/pageComponents/ShineSlider.vue";
import Facecast from "@/components/pageComponents/Facecast.vue";
import "swiper/swiper-bundle.css";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);
export default {
  metaInfo() {
    if (this.EventDetail.name) {
      return {
        title: "Az-most - " + this.EventDetail.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetail.name };
    }
  },
  name: "EventDetail",
  components: {
    DefaultSlider,
    InfoDrop,
    LibrarySlider,
    Facecast,
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    hideText: true,
    popup: false,
    openFrame: false,
    popupActivity: false,
    popupType: "",
    timerResult: { id: 0 },
    timerResultEnd: {},
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: false,
            variableWidth: true,
          },
        },
      ],
    },
    swiperSettings: {
      slidesPerView: 3,
      spaceBetween: 32,
      autoplay: {
        delay: 2000,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: "auto",
          spaceBetween: 32,
        },
        1220: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        // when window width is >= 640px
      },
    },
  }),
  computed: {
    ...mapGetters(["user", "TextList", "VideoList", "PodcastsList"]),
    sliderItems() {
      return [
        {
          name: "Виды НМГ",
          subtitle: "",
          description: "",
          color: "#DE823C",
          favorite: false,
          view: null,
          link: "https://az-most.ru/monitoring#kinds",
          _blank: true,
          watch_later: false,
          background_color: "#fbf2ec",
          nosology: [
            {
              title: "Нефрология",
              active: true,
            },
          ],
          disease: [
            {
              title: "ХБП",
              active: true,
            },
            {
              title: "СД2",
              active: true,
            },
          ],
          type: {
            name: "Публикации",
          },
          announce: require("@/assets/img/shine/library1.jpg"),
          is_new: true,
          active: true,
        },
        {
          name: "Клинические ситуации",
          subtitle: "",
          description: "",
          color: "#DE823C",
          favorite: false,
          view: null,
          link: "https://az-most.ru/monitoring#clin-cases",
          _blank: true,
          watch_later: false,
          background_color: "#fbf2ec",
          nosology: [
            {
              title: "Нефрология",
              active: true,
            },
          ],
          disease: [
            {
              title: "ХБП",
              active: true,
            },
            {
              title: "СД2",
              active: true,
            },
          ],
          type: {
            name: "Публикации",
          },
          announce: require("@/assets/img/shine/library2.jpg"),
          is_new: true,
          active: true,
        },
        {
          name: "Основные принципы мониторинга СД",
          subtitle: "",
          description: "",
          color: "#DE823C",
          favorite: false,
          view: null,
          link: "https://az-most.ru/monitoring#hba1c",
          _blank: true,
          watch_later: false,
          background_color: "#fbf2ec",
          nosology: [
            {
              title: "Нефрология",
              active: true,
            },
          ],
          disease: [
            {
              title: "ХБП",
              active: true,
            },
            {
              title: "СД2",
              active: true,
            },
          ],
          type: {
            name: "Публикации",
          },
          announce: require("@/assets/img/shine/library3.jpg"),
          is_new: true,
          active: true,
        },
      ];
    },
    shortsList() {
      return `<div class="short-list">
      * сахарный диабет 1 и 2 типа;
      <br><br>НМГ – непрерывного мониторинга глюкозы;
      <br><br>СД – сахарный диабет;
      <br><br>ГСД - гестационный сахарный диабет;
      <br><br>ИИ - искусственный интеллект.
      </div>`;
    },
    litList() {
      return `<ol>
  <li>Инструкция по применению медицинского изделия Система непрерывного мониторинга уровня глюкозы Syai (Х1) РУ № РЗН 2024/23819 от 11 октября 2024 года, Инструкция Syai_X1_RUS.2. syai.com3. Алгоритмы специализированной медицинской помощи больным сахарным диабетом / Под редакцией И.И. Дедова, М.В. Шестаковой, А.Ю. Майорова. – 11-й выпуск.– М.; 2023. doi: https://doi.org/10.14341/DM13042.</li>
  <li>syai.com</li>
  <li>Алгоритмы специализированной медицинской помощи больным сахарным диабетом / Под редакцией И.И. Дедова, М.В. Шестаковой, А.Ю. Майорова. – 11-й выпуск.– М.; 2023. doi: https://doi.org/10.14341/DM13042.</li>
</ol>`;
    },
    lectors() {
      return [
        {
          image: require("@/assets/img/shine/slides/slide1.png"),
          tag: "Председатель",
          name: "Шестакова Марина Владимировна",
          description:
            "Академик РАН, Директор Института Диабета ГНЦ РФ ФГБУ «НМИЦ эндокринологии» Минздрава России. Зав. каф. Диабетологии и диетологии ФГБУ «НМИЦ эндокринологии» Минздрава России. Заслуженный деятель науки.",
        },
        {
          image: require("@/assets/img/shine/slides/slide2.png"),
          tag: "Председатель",
          name: "Петеркова Валентина Александровна",
          description:
            "Академик РАН, д.м.н., профессор. Главный внештатный специалист детский специалист эндокринолог Министерства здравоохранения Российской Федерации, Научный руководитель ГНЦ РФ ФГБУ Института детской эндокринологии «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: require("@/assets/img/shine/slides/slide3.png"),
          tag: "Спикер",
          name: "Галстян Гагик Радикович",
          description:
            "д.м.н., профессор. Заведующий отделением диабетической стопы ФГБУ «НМИЦ эндокринологии» Минздрава России.  Руководитель экспертного центра ФГБУ «НМИЦ эндокринологии» Минздрава России. Президент ОООИ «Российская Диабетическая Ассоциация»",
        },
        {
          image: require("@/assets/img/shine/slides/slide4.png"),
          tag: "Спикер",
          name: "Лаптев Дмитрий Никитич",
          description:
            "профессор РАН, д.м.н. Заведующий детским отделением сахарного диабета, профессор кафедры детской эндокринологии ГНЦ РФ ФГБУ Института детской эндокринологии «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: require("@/assets/img/shine/slides/slide5.png"),
          tag: "Спикер",
          name: "Барсуков Илья Алексеевич",
          description:
            "К.м.н., врач-эндокринолог, старший научный сотрудник отделения терапевтической эндокринологии, доцент кафедры эндокринологии ФУВ ГБУЗ МО МОНИКИ им. М.Ф. Владимирского, главный специалист по направлению «Эндокринология» ГК МЕДСИ.",
        },
        {
          image: require("@/assets/img/shine/slides/slide6.png"),
          tag: "Спикер",
          name: "Ибрагимова Людмила Ибрагимовна",
          description:
            "к. м. н., Заведующая отделом прогнозирования и инноваций диабета , ведущий научный сотрудник ФГБУ «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: require("@/assets/img/shine/slides/slide7.png"),
          tag: "Спикер",
          name: "Сорокин Даниил Юрьевич",
          description:
            "врач-детский эндокринолог общеклинического отдела ФГБУ «НМИЦ эндокринологии» Минздрава России",
        },
      ];
    },
    EventDetail() {
      return {
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
        ],
        name: "Мониторинг глюкозы",
        date: this.$route.meta.date || "2024-12-07T10:00:17+03:00",
        date_end: "2024-12-07T12:50:23+03:00",
        webinar_id: "",
        room_id: "",
      };
    },
    program() {
      return {
        items: [
          {
            time: "10:00 – 10:10",
            title: "Открытие",
            description: "",
            icon: "person.svg",
          },
          {
            time: "10:10 – 10:15",
            title: "Вступительное слово",
            description:
              "Анциферов Михаил Борисович, д.м.н., профессор. Главный внештатный специалист эндокринолог Департамента здравоохранения города Москвы",
            icon: "person.svg",
          },
          {
            time: "10:15 – 10:30",
            title:
              "«Лучше на три часа раньше, чем на минуту позже. Ранняя интенсификация терапии СД 2 типа»",
            description:
              "Выступление профессора, д.м.н. Демидовой Татьяны Юльевны, вдохновленное произведениями Уильяма Шекспира",
            icon: "person.svg",
          },
          {
            time: "10:30 – 10:45",
            title:
              "За безопасность необходимо платить, а за ее отсутствие расплачиваться. Ключевые аспекты безопасности применения иНГЛТ-2»",
            icon: "person.svg",
            description:
              "Выступление профессора, д.м.н. Марковой Татьяны Николаевны, вдохновленное известными цитатами Уинстона Черчилля",
          },
          {
            time: "10:45 – 11:00",
            title:
              "«Знания и опыт полезны для врача. Данные Московского регистра»",
            icon: "person.svg",
            description:
              "Выступление к.м.н. Демидова Николая Александровича, вдохновленное учениями Парацельса",
          },
          {
            time: "11:00 – 11:15",
            title: "Дискуссия, ответы на вопросы аудитории",
            description: "",
            icon: "talking.svg",
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions([
      "fetchEventsCalendar",
      "fetchTextMaterials",
      "fetchVideoMaterials",
      "fetchPodcasts",
      "fetchAlerts",
    ]),
    openIframe() {
      const vm = this;
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "shine", {
              shine: {
                "broadcast watch": {
                  ...vm.$root.ymFields,
                },
              },
            });
          }
          // this.openFrame = true;
          this.popup = true;
        } else {
          // this.openFrame = true;
          this.popup = true;
        }
        // this.openFrame = true;
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "shine", {
            shine: {
              "broadcast watch": {
                ...vm.$root.ymFields,
              },
            },
          });
        }
        this.openFrame = true;
      }
    },
    copyLink() {
      var copyText = `${window.location.href}`;
      navigator.clipboard.writeText(copyText);
      this.fetchAlerts({
        alerts: "Ссылка скопирована в буфер обмена",
        type: "notify",
      });
    },
    scrollTo(selector) {
      if (selector === "programm") {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "shine", {
            shine: {
              "programm click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      const section = document.querySelector("#" + selector);

      // определяем отступ от верхнего края всей страницы (не области просмотра)
      const multyplier = this.$root.isMobile
        ? window.innerHeight
        : window.innerHeight / 2;
      const position = Math.round(section.offsetTop) + multyplier;

      // прокручиваем область просмотра до нужной позиции
      scrollTo({
        top: position,
        // плавно
        behavior: "smooth",
      });
    },
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="${require(`@/assets/img/shine/${item.icon}`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "shine", {
          shine: {
            "material click": {
              [name]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    async toRegister(reg_button) {
      if (reg_button) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "shine", {
            shine: {
              "reg click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "shine", {
            shine: {
              "join click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      localStorage.setItem("source", `forsiga1811`);
      localStorage.setItem("backUrl", JSON.stringify({ name: "SeeMore" }));
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_blank");
      window.open(this.$root.ssoLinks.registration, "_self");
    },
    openInner(title) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "shine", {
          shine: {
            "programm block click": {
              [title.split("–")[0]]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "forsiga", {
          forsiga: {
            "add to calendar": {
              ...this.$root.ymFields,
            },
          },
        });
      }
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {
    const vm = this;

    await this.fetchTextMaterials({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchVideoMaterials({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchPodcasts({
      disease: ["hbp", "hsn", "diabetes"],
    });
    if (
      !localStorage.getItem("token") &&
      !!!Object.keys(this.user).length &&
      !!this.$route.query.access &&
      !this.popup
    ) {
      setTimeout(() => {}, 10000);
    }
    let video = document.querySelector(".event-detail__timer video");
    if (video) {
      video.addEventListener("play", function () {
        if (this.currentTime === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video start", {
              "video start": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        } else {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video resume", {
              "video resume": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        vm.cyclicVideo(Math.round(this.duration));
      });
      video.addEventListener("pause", function () {
        if (this.currentTime !== this.duration) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video pause", {
              "video pause": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        clearTimeout(vm.timer);
        vm.timer = null;
      });
      video.addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video end", {
            "video end": {
              "video name": vm.EventDetail.name,
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      });
      video.addEventListener("timeupdate", function () {
        let percent = Math.ceil((this.currentTime / this.duration) * 100);
        if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video percentage watched", {
              "video percentage watched": {
                "video percentage watched": percent,
                ...vm.$root.ymFields,
              },
            });
          }
          this.percent = percent;
        }
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {},
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    width: 27px;

    @media screen and (max-width: 1219px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
      width: auto;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1219px) {
    padding-top: 40px;
  }

  .swiper-slide {
    @media screen and (max-width: 1219px) {
      width: 383px;
    }

    & img {
      max-width: 100%;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background-color: #d0006f;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1219px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      margin-right: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1219px) {
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: auto;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1219px) {
        width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1219px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1219px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1219px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;

    @media screen and (max-width: 1219px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1219px) {
      width: 100%;
    }
  }
}

.features-grid {
  margin-top: 56px;
  @media screen and (max-width: 1219px) {
    display: none;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 33px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 181px;
    border: 2px solid;
    border-radius: 16px;
    padding: 18px 32px;
    &-title {
      margin-bottom: 8px;
      font-family: "TT Rationalist Trl", sans-serif;
      font-size: 48px;
      font-weight: 600;
      line-height: 59px;
      &.blue-item {
        color: #1b98d5;
      }
      &.red-item {
        color: #dd2726;
      }
      &.green-item {
        color: #c9da2b;
      }
    }
    &.blue-item {
      border-color: #1b98d5;
    }
    &.red-item {
      border-color: #dd2726;
    }
    &.green-item {
      border-color: #c9da2b;
    }
    &__descr {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1f1f1f;
    }
  }
}
</style>

<style lang="scss" scoped>
.first-banner {
  overflow: hidden;
  height: 419px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    position: relative;
    // height: auto;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0;
    height: 550px;
  }

  &__content {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  &__title {
    color: #fff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 1219px) {
      & br {
        display: none;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 510px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      max-width: none;
      padding: 24px 32px;
    }

    @media screen and (max-width: 767px) {
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px;

    @media screen and (max-width: 1219px) {
      // width: 100%;
      // height: auto;
      // position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
      border-radius: 0;
    }
  }
}
.second-banner {
  position: relative;
  overflow: hidden;
  height: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    border-radius: 16px;
    height: 500px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 16px;
  }

  &__title {
    color: #fff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
    }
    width: fit-content;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      padding: 24px 16px;
      position: static;
      max-width: none;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom right;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
.third-banner {
  margin-top: 64px;
  position: relative;
  overflow: hidden;
  height: 485px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 1219px) {
    border-radius: 16px;
    margin-top: 32px;
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 24px;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
    }
    width: fit-content;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      padding: 24px 16px;
      background: linear-gradient(120deg, #dee2ee 50%, #bec6dd 100%);
      position: static;
      max-width: none;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
      border-radius: 0;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #6d6db1;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(4, 260px);
        overflow-x: auto;
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(4, 280px);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-shrink: 0;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
        max-width: 177px;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  &__warning {
    padding: 16px;
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    border-radius: 16px;
    background-color: #1b98d5;

    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>

<style lang="scss">
.short-list {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656969;
}
</style>

<style lang="scss" scoped>
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;

    @media screen and (max-width: 1219px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    @media screen and (max-width: 1219px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1219px) {
    padding-top: 40px;
  }

  &__tip {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1219px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1219px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 37px;
    border-radius: 16px;
    background: #1b98d5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      align-items: stretch;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1219px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      padding-right: 10px;
      margin-bottom: -5px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1219px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1219px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  .translation-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: 1219px) {
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid #fff;
    }
    &__num {
      font-family: "Roboto Slab", sans-serif;
      font-size: 48px;
      font-weight: 400;
      line-height: 62px;

      @media screen and (max-width: 767px) {
        font-size: 40px;
      }
    }
    &__text {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    &__img {
      width: 90px;
      height: 42px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1219px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1219px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1219px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1219px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;

    @media screen and (max-width: 1219px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #6d6db1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 24px;
    margin-top: 32px;
    margin-bottom: 64px;
    background-color: #f8f8f8;
    border-top: 1px solid #ebefee;
    border-right: 1px solid #ebefee;
    border-bottom: 1px solid #ebefee;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  &__date-title {
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

    @media screen and (max-width: 1219px) {
      margin-bottom: 24px;
    }
  }

  &__date-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 1219px) {
      margin-bottom: 24px;
    }
  }

  &__about-title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #3c4242;
  }

  &__date-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1219px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__date-button {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1219px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1219px) {
      width: 100%;
    }
  }
}

.seemore {
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}
</style>

<style lang="scss" scoped>
.slide-lector {
  // padding: 24px;
  border-radius: 16px;
  height: 100%;

  &__image-container {
    width: 280px;
    height: 270px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #6d6db1;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0;
}

.dropdowns {
  margin-bottom: -124px;
  padding-bottom: 124px;
  padding-top: 32px;
  background-color: #f8f8f8;
}
</style>

<style lang="scss">
.seemore .default-slider__arrows {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.seemore .programm-list__title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #1b98d5;
}
</style>
